import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import ServiceProp from '../component/service/ServiceProp';
import ServiceData from "../data/service/ServiceMain.json";
import { slugify } from '../utils';

const allData = ServiceData;


const ServiceOne = () => {

    const companyRegistrationData = allData.filter(data => slugify(data.cate ? data.cate : "") === "cat1");
    const cat2Data = allData.filter(data => slugify(data.cate ? data.cate : "") === "cat2");
    const cat3Data = allData.filter(data => slugify(data.cate ? data.cate : "") === "cat3");
    const cat4Data = allData.filter(data => slugify(data.cate ? data.cate : "") === "cat4");
    const cat5Data = allData.filter(data => slugify(data.cate ? data.cate : "") === "cat5");
    const cat6Data = allData.filter(data => slugify(data.cate ? data.cate : "") === "cat6");

    return (
        <>
        <SEO title="Service One" />
        <ColorSwitcher />
        <main className="main-wrapper">
            <HeaderOne />
            <BcrumbBannerOne 
                title="BEST SOLUTIONS FOR YOUR BUSINESS" 
                paragraph ="We're here to help you grow your business."
                styleClass=""
                mainThumb="/images/others/case-study-5_.png"
            />
            <div className="service-scroll-navigation-area">

                {/* Service Nav */}
                
                <nav id="onepagenav" className="service-scroll-nav navbar onepagefixed">
                    <div className="container">
                        <ul className="nav nav-pills">
                        <li className="nav-item">
                            <a className="nav-link" href="#section1">Company Registration</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#section2">Visa and Residency Service</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#section3">Legal Consulting Service</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#section4">IT Services</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#section5">Financial Services</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#section6">3PL Services</a>
                        </li>
                        </ul>
                    </div>
                </nav>

                <div className="section section-padding" id="section1">
                    <div className="container">
                        <SectionTitle 
                            subtitle=""
                            title="Company Registration"
                            description=""
                            textAlignment="heading-left"
                            textColor=""
                        
                        />
                        <div className="row">
                            <ServiceProp colSize="col-lg-4 col-md-6" serviceStyle="service-style-2" serviceData={companyRegistrationData}/>
                        </div>
                    </div>
                </div>

                <div className="section section-padding bg-color-light" id="section2">
                    <div className="container">
                        <SectionTitle 
                            subtitle=""
                            title="Visa and Residency Service"
                            description=""
                            textAlignment="heading-left"
                            textColor=""
                        
                        />
                        <div className="row">
                            <ServiceProp colSize="col-lg-4 col-md-6" serviceStyle="service-style-2" serviceData={cat2Data}/>
                        </div>
                    </div>
                </div>

                <div className="section section-padding" id="section3">
                    <div className="container">
                        <SectionTitle 
                            subtitle=""
                            title="Legal Consulting Service"
                            description=""
                            textAlignment="heading-left"
                            textColor=""
                        
                        />
                        <div className="row">
                            <ServiceProp colSize="col-lg-4 col-md-6" serviceStyle="service-style-2" serviceData={cat3Data}/>
                        </div>
                    </div>
                </div>

                <div className="section section-padding bg-color-light" id="section4">
                    <div className="container">
                        <SectionTitle 
                            subtitle=""
                            title="IT services"
                            description=""
                            textAlignment="heading-left"
                            textColor=""
                        
                        />
                        <div className="row">
                            <ServiceProp colSize="col-lg-4 col-md-6" serviceStyle="service-style-2" serviceData={cat4Data}/>
                        </div>
                    </div>
                </div>

                <div className="section section-padding" id="section5">
                    <div className="container">
                        <SectionTitle 
                            subtitle=""
                            title="Financial Services"
                            description=""
                            textAlignment="heading-left"
                            textColor=""
                        
                        />
                        <div className="row">
                            <ServiceProp colSize="col-lg-4 col-md-6" serviceStyle="service-style-2" serviceData={cat5Data}/>
                        </div>
                    </div>
                </div>

                <div className="section section-padding bg-color-light" id="section6">
                    <div className="container">
                        <SectionTitle 
                            subtitle=""
                            title="3PL Services"
                            description=""
                            textAlignment="heading-left"
                            textColor=""
                        
                        />
                        <div className="row">
                            <ServiceProp colSize="col-lg-4 col-md-6" serviceStyle="service-style-2" serviceData={cat6Data}/>
                        </div>
                    </div>
                </div>
            </div>
            <CtaLayoutOne />
            <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default ServiceOne;