import React from 'react';
import FormOne from '../contact/FormOne';
import Accordion from 'react-bootstrap/Accordion';
import { FaCompress, FaCode, FaGlobe, FaAdjust, FaBusinessTime, FaPersonBooth, FaWindowMinimize, FaAngellist, FaEarlybirds, FaMoneyBillAlt, FaAddressCard, FaAccessibleIcon, FaAcquisitionsIncorporated, FaTemperatureHigh, FaStarOfDavid, FaAd, FaNewspaper, FaShoppingCart, FaIdCard, FaCartPlus } from 'react-icons/fa';


const AboutTwo = () => {
    return (
        <div className="section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="why-choose-us">
                            <div className="section-heading heading-left">
                                {/* <span className="subtitle">About Us</span>
                                <h3 className="title">Why branding matters?</h3>*/}
                                <p>
                                    Investors from all over the world choose Dubai to make a home for their families and a headquarter for their global businesses.
                                </p>
                                <p>
                                    The favorable business conditions in one of the world’s fastest growing economies enabled by decades-long market friendly government policies, have positioned Dubai as the business, financial and commercial hub of the region.
                                </p>
                                <p>
                                    Preferred by tourists, entrepreneurs, and investors alike, Dubai is a diverse, multicultural, and exciting haven for outstanding business opportunities while maintaining a rewarding lifestyle.
                                </p>
                                <p>
                                    Dubai encourages and fosters foreign investment by tailoring a unique investment-driven visa program to legally reside in the UAE, allowing investors to build a secure future for their families as well as their businesses
                                </p>

                                <h4>The Dubai Investor Visa</h4>

                                <p>
                                    The Dubai Investor Visa, also called the Dubai Partner visa, is applicable to foreign nationals who want to start their own business or invest in an existing business in Dubai.
                                </p>
                                <p>
                                    The Dubai investor visa grants the holder the right to reside in the UAE, and to come and go as they wish for the duration of the visa. A holder of the Dubai Investor Visa becomes a tax domicile of the UAE and is not taxable by the country of their nationality, however this is subject to the investor’s home country regulations.
                                </p>
                                <p>
                                    A holder of a Dubai Investor Visa is eligible to apply for the Emirates ID card, open a bank account, acquire a UAE driving license, sign up for Etisalat or Du mobile and home packages, and many other essential services.  The Dubai Investor Visa also grants its holder the permission to sponsor their spouse, children, and parents to enter the UAE.
                                </p>
                                <p>
                                    ABTIX LLC is your one-stop shop for visa and residency packages in UAE. Our visa and residency packages including :
                                </p>

                                <div className="row">
                                    <div className="col-md-12 col-sm-12 mb--30">
                                        <ul className="list-style">
                                            <li>Investment Visa</li>
                                            <li>Employee Visa</li>
                                            <li>Bank Account Opening</li>
                                            <li>Credit Card</li>
                                            <li>Health Insurance</li>
                                            <li>Life Insurance</li>
                                            <li>Hotel Arrangement and Pickup Arrangements</li>
                                        </ul>
                                    </div>
                                </div>
                                <p>
                                    For more information on obtaining residency through starting a business, Our team is waiting to help you, whether it’s feedback, a question about our services, general advice
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6 offset-xl-1">
                        <div className="contact-form-box shadow-box mb--30">
                            <h3 className="title">Get a quote now</h3>
                            <FormOne />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutTwo;