import React from 'react';
import { Link } from 'react-router-dom';


const AboutFive = () => {
    return (
      <div className="section-padding-equal">
            <div className="container">
                <div className="row align-items-center">
                <div className="col-lg-7">
                    <div className="about-team">
                    <div className="thumbnail">
                        <img src={process.env.PUBLIC_URL + "/images/about/about-2_.png"} alt="thumbnail" />
                    </div>
                    </div>
                </div>
                <div className="col-lg-5">
                    <div className="about-team">
                    <div className="section-heading heading-left">
                        <span className="subtitle">At ABTIX</span>
                        <h2>We believe that together we can do so much more than we could ever do alone.</h2>  
                        <p>We are a team of professionals from different industries, united by our shared vision for a world that is safer, 
                            smarter and greener than it is today. We are the IT and legal teams you need to build your business; 
                            we are the oil and gas experts who can help you improve your operations; we are the construction specialists 
                            who can provide you with the tools necessary to make your dream a reality. Because together, we're able to give you the best service possible.</p>
                        {/* <Link to="#" className="axil-btn btn-large btn-fill-primary">Our Team</Link> */}
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default AboutFive;