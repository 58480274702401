import React from 'react';
import FormOne from '../contact/FormOne';
import Accordion from 'react-bootstrap/Accordion';
import { FaCompress, FaCode, FaGlobe, FaAdjust, FaBusinessTime, FaPersonBooth, FaWindowMinimize, FaAngellist, FaEarlybirds, FaMoneyBillAlt, FaAddressCard, FaAccessibleIcon, FaAcquisitionsIncorporated, FaTemperatureHigh, FaStarOfDavid, FaAd, FaNewspaper, FaShoppingCart, FaIdCard, FaCartPlus } from 'react-icons/fa';


const AboutTwo = () => {
    return (
        <div className="section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="why-choose-us">
                            <div className="section-heading heading-left">
                                {/* <span className="subtitle">About Us</span>
                                <h3 className="title">Why branding matters?</h3>*/}
                                <p>
                                    As the business capital of the Middle East, Dubai offers a strategic location, innovative infrastructure,
                                    and supportive start-up culture. This, combined with newly-introduced regulations and fast approval processes,
                                    means there has never been a better time to set up your business in Dubai mainland & Free zone.
                                </p>
                                <p>
                                    Dubai company registration is not an easy task. But, the experienced and qualified team of ABTIX can make it possible without any hassle.
                                    Get a business license, We exist to assist you with the complete business registration process in Dubai Mainland & Free Zone.
                                </p>
                            </div>
                            <Accordion defaultActiveKey="1">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header><FaBusinessTime /> It is Tax-Free!</Accordion.Header>
                                    <Accordion.Body>
                                        Taxation laws in the UAE are very lenient when compared to many other countries of the world.
                                        With Dubai growing into a competitive business center of the world, it has been a magnet for startups as well as established businesses
                                        looking for a tax-free zone to set up their business.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header><FaIdCard /> You Can Get Tax Residency</Accordion.Header>
                                    <Accordion.Body>
                                        Starting a business in Dubai gives business owners the opportunity of taking up residence in the UAE. This reduces their overall
                                        tax burden and helps them enjoy higher profitability through the products or services that they provide.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header><FaCartPlus /> Dubai is a Growing Business Hub</Accordion.Header>
                                    <Accordion.Body>
                                        Businesses often tend to thrive in a region where other businesses have already established their foundations successfully.
                                        This is because the region becomes viable in a number of ways for other businesses by means of infrastructure, accessibility to talent, and ease of transportation.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header><FaGlobe /> Banking in Dubai is Easy and Smooth</Accordion.Header>
                                    <Accordion.Body>
                                        Businesses get a genuine boost in their operations when they have an organized banking system to support their growth requirements.
                                        The success of a company largely depends on the availability of the right form of credit, ease of banking, and accessibility to banks that have a global presence.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6 offset-xl-1">
                        <img src={process.env.PUBLIC_URL + "/images/services/company_registration/uae_banner.jpg"} alt="travel" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutTwo;