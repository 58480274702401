import React from 'react';
import FormOne from '../contact/FormOne';
import Accordion from 'react-bootstrap/Accordion';
import { FaCompress, FaCode, FaGlobe, FaAdjust, FaBusinessTime, FaPersonBooth, FaWindowMinimize, FaAngellist, FaEarlybirds, FaMoneyBillAlt, FaAddressCard, FaAccessibleIcon, FaAcquisitionsIncorporated, FaTemperatureHigh, FaStarOfDavid, FaAd, FaNewspaper, FaShoppingCart } from 'react-icons/fa';


const AboutTwo = () => {
    return (
        <div className="section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="why-choose-us">
                            <div className="section-heading heading-left">
                                {/* <span className="subtitle">About Us</span>
                                <h3 className="title">Why branding matters?</h3>*/}
                                <p>
                                    Economic incentives for foreign investment are also key factors in this growing economy.
                                </p>
                                <p>
                                    Erbil has enjoyed unprecedented development since 2003 and moved forward with the international community to explore a wide range of economic activity, reserving a lynchpin role for foreign investors, multinational companies, and public-private partnerships. The analysis is key to any investment strategy and this all-source compilation of data on the Erbil region provides the necessary indicators to perform due diligence.
                                </p>
                                <p>
                                    Huge corporations from various countries have allocated large funds for purchasing real estate properties in Kurdistan, including the USA, Turkey, Iran, Canada, Saudi Arabia, Kuwait, the UAE, the UK, France, and China.
                                </p>
                                <p>
                                    With years of expertise and a highly experienced and competent team comprising lawyers, certified consultants, translators, well-connected public relations officers, and corporate advisors with extensive knowledge of the KRG operational framework, ABTIX will help you to start your business journeys From helping them figure out the most suitable jurisdiction, trade license, and corporate structure based on the nature of the business, to obtaining all required documents and approvals from various government agencies
                                </p>
                                <p>
                                    ABTIX LLC is here to support you in every step of your business development.
                                </p>
                                <p>
                                    We know that starting a business is a big step, which is why we've got your back with the name, company formation, registration with the Ministry of Natural Resources and Lawyer registration along with finding an office for your business in Erbil.                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6 offset-xl-1">
                        <img
                            src={process.env.PUBLIC_URL + "/images/services/company_registration/krg_banner.jpg"}
                            alt="krg_banner"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutTwo;