import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';

const Datas = [
    {
        id: 1,
        title: "At ABTIX, we specialize in helping you register your company in the United States.",       
        para: "Whether you're an entrepreneur looking to expand your business globally or an established business looking to expand into the U.S., we offer a variety of services that can make registering your company as easy as possible.",
    },
    {
        id: 2,
        title: "We understand that many of our clients are just starting out in their careers",
        para: "That's why we pride ourselves on making registering a company as easy as possible for you. We will help you understand how each step works, so you can feel confident in every move you make towards starting up in America, UAE, Iraq and Kurdistan Region."
    },
    {
        id: 3,
        title: "We also understand that customer trust is paramount when it comes to growing your business",
        para: "Which is why we offer services such as opening a U.S. Business Bank Account and acquiring U.S. customers through merchant accounts with Stripe/Paypal/Amazon to help ensure that not only will you be able to start up in America confidently, but that your customers will feel confident purchasing from an American-based company too!"
    }
]




const AboutThree = () => {
    return (
        <div className="section section-padding bg-color-dark pb--80 pb_lg--40 pb_md--20">
            <div className="container">
                <SectionTitle 
                    subtitle="Our Valus"
                    title="Why should you work with us?"
                    description=""
                    textAlignment="heading-left heading-light-left mb--100"
                    textColor=""
                />

                <div className="row">
                    {Datas.map((data) => (
                        <div className="col-lg-4" key={data.id}>
                            <div className="about-quality">
                                <h3 className="sl-number">{data.id}</h3>
                                <h5 className="title">{data.title}</h5>
                                <p>{data.para}</p>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
            <ul className="list-unstyled shape-group-10">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-1.png"} alt="Circle" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-3.png"} alt="Circle" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"} alt="Circle" /></li>
            </ul>
        </div>
    )
}

export default AboutThree;