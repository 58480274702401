import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import PricingData from "../../data/pricing/PricingUkCoData.json";
import { FaCheck} from "react-icons/fa";


const getPriceingData = PricingData;

const PricingUkCo = () => {

    return (
        <>
            <div className="row">
                {getPriceingData.map((data, index) => (
                    <div className="col-lg-3 pricing-col" key={index}>
                        <div className="pricing-table pricing-borderd">
                            <div className="pricing-header">
                                <h3 className="title">{data.title}</h3>
                                <span className="subtitle">{data.subtitle}</span>
                                <div className="price-wrap">
                                    <div className="yearly-pricing">
                                        <span className="amount">{ data.mprice }</span>
                                        <span className="duration">{ data.mduration }</span>
                                    </div>
                                </div>
                                <div className="pricing-btn">
                                    <Link to="#" className="axil-btn btn-large btn-borderd">Get Started</Link>
                                </div>
                            </div>
                            <div className="pricing-body">
                                <ul className="list-unstyled">
                                    {data.facility.map((data, index) => (
                                        <li key={index}><FaCheck /> {data}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default PricingUkCo;