import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import ScrollToTop from './component/scrollToTop/ScrollToTop';
import 'bootstrap/dist/css/bootstrap.min.css';


// Home Pages Import
import DigitalAgency from './pages/DigitalAgency';
import CreativeAgency from './pages/CreativeAgency';
import PersonalPortfolio from './pages/PersonalPortfolio';
import HomeStartup from './pages/HomeStartup';
import CorporateAgency from './pages/CorporateAgency';

// Blog Import
import BlogGridView from './pages/BlogGrid';
import BlogCategory from './pages/Category';
import BlogArchive from './pages/Archive';
import BlogDetails from './pages/BlogDetails';

// Service 
import ServiceOne from './pages/ServiceOne';
import ServiceTwo from './pages/ServiceTwo';
import ServiceTwoCustom from './pages/ServiceTwoCustom';
import ServiceTwoCustom2 from './pages/ServiceTwoCustom2';
import ServiceTwoCustom3 from './pages/ServiceTwoCustom3';
import ServiceTwoCustom4 from './pages/ServiceTwoCustom4';
import ServiceTwoCustom5 from './pages/ServiceTwoCustom5';
import ServiceDetails from './pages/ServiceDetails';
import ServiceDetails_USCompanyRegistration from './pages/ServiceDetails_USCompanyRegistration';
import ServiceDetails_UAECompanyRegistration from './pages/ServiceDetails_UAECompanyRegistration';
import ServiceDetails_UKCompanyRegistration from './pages/ServiceDetails_UKCompanyRegistration';
import ServiceDetails_IraqCompanyRegistration from './pages/ServiceDetails_IraqCompanyRegistration';
import ServiceDetails_KRGCompanyRegistration from './pages/ServiceDetails_KRGCompanyRegistration';

import ServiceDetails_USVisa from './pages/ServiceDetails_USVisa';
import ServiceDetails_UAEVisa from './pages/ServiceDetails_UAEVisa';
import ServiceDetails_UKVisa from './pages/ServiceDetails_UKVisa';
import ServiceDetails_KRGVisa from './pages/ServiceDetails_KRGVisa';

// Project 
import ProjectGridOne from './pages/ProjectGridOne';
import ProjectGridTwo from './pages/ProjectGridTwo';
import ProjectGridThree from './pages/ProjectGridThree';
import ProjectGridFive from './pages/ProjectGridFive';
import ProjectGridFour from './pages/ProjectGridFour';
import ProjectDetails from './pages/ProjectDetails';

// Pages 
import Splash from './pages/Splash';
import AboutUs from './pages/AboutUs';
import OurOffice from './pages/OurOffice';
import OurClients from './pages/OurClients';
import Team from './pages/Team';
import TeamDetails from './pages/TeamDetails';
import CaseStudy from './pages/CaseStudy';
import CaseDetails from './pages/CaseStudyDetails';
import Testimonials from './pages/Testimonials';
import PricingTable from './pages/PricingTable';
import Typography from './pages/Typography';
import Contact from './pages/Contact';
import ErrorPage from './pages/404';
import ComingSoon from './pages/ComingSoon';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';

// Css Import
import './assets/scss/app.scss';
import Quote from './pages/Quote';


const App = () => {
  return (
    <Router>
		<ScrollToTop>
			<Routes>
				<Route path={process.env.PUBLIC_URL + "/"} element={<HomeStartup />}/>

				<Route path={process.env.PUBLIC_URL + "/digital-agency"} element={<DigitalAgency />}/>
				<Route path={process.env.PUBLIC_URL + "/creative-agency"} element={<CreativeAgency />}/>
				<Route path={process.env.PUBLIC_URL + "/personal-portfolio"} element={<PersonalPortfolio />}/>
				<Route path={process.env.PUBLIC_URL + "/home-startup"} element={<HomeStartup />}/>
				<Route path={process.env.PUBLIC_URL + "/corporate-agency"} element={<CorporateAgency />}/>

				{/* Blogs */}
				<Route path={process.env.PUBLIC_URL + "/blog/"} element={<BlogGridView />}/>
				<Route path={process.env.PUBLIC_URL + "/category/:slug"} element={<BlogCategory />}/>
				<Route path={process.env.PUBLIC_URL + "/archive/:slug"} element={<BlogArchive />}/>
				<Route path={process.env.PUBLIC_URL + "/blog-details/:id"} element={<BlogDetails />}/>

				{/* Service */}
				<Route path={process.env.PUBLIC_URL + "/services/"} element={<ServiceOne />}/>
				<Route path={process.env.PUBLIC_URL + "/service-two/"} element={<ServiceTwo />}/>
				<Route path={process.env.PUBLIC_URL + "/service-details/:slug"} element={<ServiceDetails />}/>

				<Route path={process.env.PUBLIC_URL + "/company-registration/"} element={<ServiceTwoCustom pageTitle="Company Registration" cateFilter={"cat1"} />}/>
				<Route path={process.env.PUBLIC_URL + "/visa-residency"} element={<ServiceTwoCustom2 pageTitle="Visa & Residency Service" cateFilter={"cat2"} />}/>
				<Route path={process.env.PUBLIC_URL + "/legal-consulting/"} element={<ServiceTwoCustom3 pageTitle="Legal Consulting Service" cateFilter={"cat3"} />}/>
				<Route path={process.env.PUBLIC_URL + "/information-technology/"} element={<ServiceTwoCustom4 pageTitle="IT Services" cateFilter={"cat4"} />}/>
				<Route path={process.env.PUBLIC_URL + "/financial-service/"} element={<ServiceTwoCustom5 pageTitle="Financial Services" cateFilter={"cat5"} />}/>

				<Route path={process.env.PUBLIC_URL + "/united-states-company-registration"} element={<ServiceDetails_USCompanyRegistration />}/>
				<Route path={process.env.PUBLIC_URL + "/united-arab-emirates-company-registration"} element={<ServiceDetails_UAECompanyRegistration />}/>
				<Route path={process.env.PUBLIC_URL + "/united-kingdom-company-registration"} element={<ServiceDetails_UKCompanyRegistration />}/>
				<Route path={process.env.PUBLIC_URL + "/iraq-company-registration"} element={<ServiceDetails_IraqCompanyRegistration />}/>
				<Route path={process.env.PUBLIC_URL + "/kurdistan-region-company-registration"} element={<ServiceDetails_KRGCompanyRegistration />}/>

				<Route path={process.env.PUBLIC_URL + "/united-arab-emirates-visa-residency"} element={<ServiceDetails_UAEVisa />}/>
				<Route path={process.env.PUBLIC_URL + "/united-kingdom-visa-residency"} element={<ServiceDetails_UKVisa />}/>
				<Route path={process.env.PUBLIC_URL + "/kurdistan-region-visa-residency"} element={<ServiceDetails_KRGVisa />}/>
				<Route path={process.env.PUBLIC_URL + "/united-states-visa-consulting"} element={<ServiceDetails_USVisa />}/>

				{/* Project  */}
				<Route path={process.env.PUBLIC_URL + "/project-grid-one"} element={<ProjectGridOne />}/>
				<Route path={process.env.PUBLIC_URL + "/project-grid-two"} element={<ProjectGridTwo />}/>
				<Route path={process.env.PUBLIC_URL + "/project-grid-three"} element={<ProjectGridThree />}/>
				<Route path={process.env.PUBLIC_URL + "/project-width-one"} element={<ProjectGridFour />}/>
				<Route path={process.env.PUBLIC_URL + "/project-width-two"} element={<ProjectGridFive />}/>
				<Route path={process.env.PUBLIC_URL + "/project-details/:slug"} element={<ProjectDetails />}/>

				{/* Pages  */}
				<Route path={process.env.PUBLIC_URL + "/about-us"} element={<AboutUs />}/>
				<Route path={process.env.PUBLIC_URL + "/our-office"} element={<OurOffice />}/>
				<Route path={process.env.PUBLIC_URL + "/our-clients"} element={<OurClients />}/>
				<Route path={process.env.PUBLIC_URL + "/team"} element={<Team />}/>
				<Route path={process.env.PUBLIC_URL + "/team-details/:slug"} element={<TeamDetails />}/>
				<Route path={process.env.PUBLIC_URL + "/case-study"} element={<CaseStudy />}/>
				<Route path={process.env.PUBLIC_URL + "/case-details/:slug"} element={<CaseDetails />}/>
				<Route path={process.env.PUBLIC_URL + "/testimonials"} element={<Testimonials />}/>
				<Route path={process.env.PUBLIC_URL + "/pricing-table"} element={<PricingTable />}/>
				<Route path={process.env.PUBLIC_URL + "/typography"} element={<Typography />}/>
				<Route path={process.env.PUBLIC_URL + "/contact"} element={<Contact />}/>
				<Route path={process.env.PUBLIC_URL + "/404"} element={<ErrorPage />}/>
				<Route path={process.env.PUBLIC_URL + "/coming-soon"} element={<ComingSoon />}/>
				<Route path={process.env.PUBLIC_URL + "/privacy-policy"} element={<PrivacyPolicy />}/>
				<Route path={process.env.PUBLIC_URL + "/terms-use"} element={<TermsOfUse />}/>
				<Route path={process.env.PUBLIC_URL + "/quate"} element={<Quote />}/>

			</Routes>
		</ScrollToTop>
    </Router>
  )
}

export default App;
