import React from 'react';
import FormOne from '../contact/FormOne';
import Accordion from 'react-bootstrap/Accordion';
import { FaCompress, FaCode, FaGlobe, FaAdjust, FaBusinessTime, FaPersonBooth, FaWindowMinimize, FaAngellist, FaEarlybirds, FaMoneyBillAlt, FaAddressCard, FaAccessibleIcon, FaAcquisitionsIncorporated, FaTemperatureHigh, FaStarOfDavid, FaAd, FaNewspaper, FaShoppingCart } from 'react-icons/fa';


const AboutTwo = () => {
    return (
        <div className="section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="why-choose-us">
                            <div className="section-heading heading-left">
                                {/* <span className="subtitle">About Us</span>
                                <h3 className="title">Why branding matters?</h3>*/}
                                <p>
                                    ABTIX consultancy  has a complete team composed of many  lawyers who are specialized in the field of residence and entry visa into Iraqi Kurdistan Region. Additionally, working knowledge, credibility and punctuality are major specifications of these staff of that particular department of  ABTIX consultancy . We, as ABTIX consultancy, seriously work on performing the work of foreign citizens without any tiredness or delay in work.
                                </p>
                                <p>
                                    ABTIX consultancy in Erbil/ Kurdistan Region/ Iraq is committed to providing clients with legal consultation or legal services in several areas according to the order and need of our clients including the companies working with us, the employees working in those companies, and normal citizens clients as well. In order to develop the label of the productive and commercial companies working with ABTIX for Advocates and Legal Consultancy, we consolidate all physical and human capabilities to create a legally appropriate environment and legal protection for the clients from the friend companies, we are seriously working on performing the work of foreign citizens without any tiredness or delay in work
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6 offset-xl-1">
                        <div className="contact-form-box shadow-box mb--30">
                            <h3 className="title">Get a quote now</h3>
                            <FormOne />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutTwo;