import React from 'react';
import FormOne from '../contact/FormOne';
import Accordion from 'react-bootstrap/Accordion';
import { FaCompress, FaCode, FaGlobe, FaAdjust, FaBusinessTime, FaPersonBooth, FaWindowMinimize, FaAngellist, FaEarlybirds, FaMoneyBillAlt, FaAddressCard, FaAccessibleIcon, FaAcquisitionsIncorporated, FaTemperatureHigh, FaStarOfDavid, FaAd, FaNewspaper, FaShoppingCart } from 'react-icons/fa';


const AboutTwo = () => {
    return (
        <div className="section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="why-choose-us">
                            <div className="section-heading heading-left">
                                {/* <span className="subtitle">About Us</span>
                                <h3 className="title">Why branding matters?</h3>*/}
                                <p>
                                    As was the case in many other highly developed countries over the past five years, the United Kingdom’s economic growth turned negative in 2020 before resuming in 2021.
                                    However, a five-year trend of robust economic freedom has stumbled a bit. With lower marks for fiscal health, business freedom, and labor freedom, the U.K.
                                    has recorded a 3.7-point overall loss of economic freedom since 2017 and has fallen from the upper half to the lower half of the “Mostly Free” category.
                                    Monetary freedom and trade freedom is vital, but excessive government spending has been a drag on fiscal health.
                                </p>
                            </div>
                            <h4>ADVANTAGES</h4>
                            <div className="row">
                                <div className="col-md-12 col-sm-12 mb--30">
                                    <ul className="list-style">
                                        <li>Limited liability protection</li>
                                        <li>Better business financing</li>
                                        <li>Tax advantages</li>
                                        <li>Protected business name</li>
                                        <li>Fundraising opportunities</li>
                                        <li>International reputation</li>
                                        <li>Professional image</li>
                                        <li>Inexpensive formation cost</li>
                                    </ul>
                                </div>
                            </div>
                            <p>
                                With ABTIX Your Company formation mission statement is simple,
                                we will help you start a business and form your company worldwide Our services provide a platform to maximize the chances of your company becoming a real success.
                            </p>
                        </div>
                    </div>
                    {/* <div className="col-xl-5 col-lg-6 offset-xl-1">
                        <div className="contact-form-box shadow-box mb--30">
                            <h3 className="title">Get a quote now</h3>
                            <FormOne />
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default AboutTwo;