import React from 'react';
import FormOne from '../contact/FormOne';
import Accordion from 'react-bootstrap/Accordion';
import { FaCompress, FaCode, FaGlobe, FaAdjust, FaBusinessTime, FaPersonBooth, FaWindowMinimize, FaAngellist, FaEarlybirds, FaMoneyBillAlt, FaAddressCard, FaAccessibleIcon, FaAcquisitionsIncorporated, FaTemperatureHigh, FaStarOfDavid, FaAd, FaNewspaper, FaShoppingCart, FaHome } from 'react-icons/fa';
import AccordionHeader from 'react-bootstrap/esm/AccordionHeader';


const AboutTwo = () => {
    return (
        <div className="section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="why-choose-us">
                            <div className="section-heading heading-left">
                                {/* <span className="subtitle">About Us</span>
                                <h3 className="title">Why branding matters?</h3>*/}
                                <p>United States provides the largest market in the world. It also has the lowest tax rates, it can definitely enhance your business,
                                    and registering there would give you some extra advantages for the success of your business in the USA.
                                    you don't need to be present physically in the country all the procedures can be done online within seven days.
                                    There are many kinds of companies in the USA like private, public, Limited Liability Partnerships, etc.
                                    Registrations of different types of companies have to be taken differently.
                                    They have their own pros and cons. In this write-up, we will talk about the benefits of company registration the state of USA.</p>
                                <p>
                                    There are many advantages or benefits of registering a company in the USA.
                                </p>
                                <p>
                                    ABTIX offers the service of registration of companies in the USA and other accompanying legal services to clients.
                                    With our services, you will reduce the time required for the collection of documentation,
                                    as well as significantly increase the chances for quicker registration due to proper filing and documentation.
                                </p>
                            </div>
                            <Accordion defaultActiveKey="1">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header><FaGlobe /> International standing</Accordion.Header>
                                    <Accordion.Body>
                                        The USA is already known for its market size internationally;
                                        registering a company in the USA would give you distinct standing and recognition in the market and would also attract new partners.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header><FaBusinessTime /> Business-friendly legislation</Accordion.Header>
                                    <Accordion.Body>
                                        The USA is also known for its business-friendly laws and regulations.
                                        Compared to other countries running a business in the US market is a lot easier and many new entrepreneurs are attracted to the US market because tax rates are low.
                                        All laws and regulations revolve around incorporation and it also promotes ease of doing business.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header><FaAddressCard /> Minimize personal liabilities</Accordion.Header>
                                    <Accordion.Body>
                                        Once you registered your company in the United States of America,
                                        it immediately has its own separate legal identity and it also protects your personal assets against your business actions,
                                        it is also accountable and responsible for any debt arising out of business activities,
                                        which means in case of bankruptcy of the company the creditors can only seek the payment only from company assets but not from your personal assets.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header><FaBusinessTime /> Easy access to capital</Accordion.Header>
                                    <Accordion.Body>
                                        When you registered your company in the United States of America, it allows you to issue shares in the share market and also raise equity capital.
                                        Every business needs a regular supply of capital for expansion of the business.
                                        Investors tend to invest only in the companies that are being registered because registered companies tend to give more profits.  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header><FaAd /> Enhance creditability and brand awareness </Accordion.Header>
                                    <Accordion.Body>
                                    One of the important benefits of registering your company in the USA is it enhances creditability and creates brand awareness.
                                     Registering a company in the United States of America will give some extra advantages like many giant companies'
                                      tie-up or engaging with only registered companies.
                                     It also attracts consumers and investors because they perceive that registered companies are far more stable than non-registered companies.  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                    <Accordion.Header><FaShoppingCart /> Newmarket</Accordion.Header>
                                    <Accordion.Body>
                                    Many infamous cities in the United States of America are providing extra tax concessions to attract new entrepreneurs to their cities and encouraging them to invest in their communities because of that you may have numerous opportunities to choose the best suitable place for your company. Registering a company in the USA will give you a new market that suits your business. </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey='7'>
                                    <AccordionHeader><FaHome/>Virtual Office</AccordionHeader>
                                        We are offering you virtual office with real business address, if you want to lease new office, we can help you with that.
                                        <br/>
                                        You don't need to be an individual or company to take advantage of our services.
                                </Accordion.Item>


                            </Accordion>
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6 offset-xl-1">
                        {/* <div className="contact-form-box shadow-box mb--30"> */}
                        <img src={process.env.PUBLIC_URL + "/images/services/company_registration/us_banner.jpg"} alt="travel" />

                            {/* <h3 className="title">Get a quote now</h3>
                            <FormOne /> */}
                        {/* </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutTwo;