import React from 'react';
import FormOne from '../contact/FormOne';
import Accordion from 'react-bootstrap/Accordion';
import { FaCompress, FaCode, FaGlobe, FaAdjust, FaBusinessTime, FaPersonBooth, FaWindowMinimize, FaAngellist, FaEarlybirds, FaMoneyBillAlt, FaAddressCard, FaAccessibleIcon, FaAcquisitionsIncorporated, FaTemperatureHigh, FaStarOfDavid, FaAd, FaNewspaper, FaShoppingCart } from 'react-icons/fa';

const AboutTwo = () => {
    return (
        <div className="section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="why-choose-us">
                            <div className="section-heading heading-left">
                                {/* <span className="subtitle">About Us</span>
                                <h3 className="title">Why branding matters?</h3>*/}
                                <p>
                                    Individuals of talent and means need not limit their lives and business interests to one country. Residence by investment programs, also known as  provide high-net-worth individuals with the option to relocate and the right to live, work, study, and receive healthcare in their new countries of residence. Being able to change residence to a suitable country or have multiple residences is an increasingly important aspect of international planning for private clients.
                                </p>
                                <p>
                                    As a Businessperson, you may be finding difficult to navigate through the UK Immigration Rules in terms of finding the right visa for you. The task is not made any easier with the Innovator Visa. Applicants have commented how difficult it can be to access Endorsing Bodies and meet the requirements of the new business
                                </p>
                                <p>
                                    ABTIX counsulting offers expert advice on residence by investment programs in some of the world’s top places in terms of business environment, quality of life, and the possibility of acquiring citizenship.
                                </p>
                                <Accordion defaultActiveKey="1">
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header><FaBusinessTime /> What is a UK Innovator Visa?</Accordion.Header>
                                        <Accordion.Body>
                                            The UK Innovator visa is designed for foreign entrepreneurs who plan to run their own business in the UK. It is ideal for business people with an innovative business idea who want to set up a company in the UK or for experienced entrepreneurs that have been running a business outside the United Kingdom for many years and wish to expand it to the UK. You can establish a company in the UK either by yourself or with a business partner. To obtain a UK Innovator visa, you must invest in a new business which means it is not allowed to invest in an existing one.
                                            A UK Innovator visa allows you to apply for an Indefinite Leave to Remain (ILR) and UK citizenship. Once your business idea is endorsed, all you have to do is to establish your business, take an active part in its development in the UK and other markets, ensure its growth, increase profits and create jobs.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header><FaBusinessTime /> Benefits of UK Innovator Visa</Accordion.Header>
                                        <Accordion.Body>
                                            A UK Innovator visa will give you access to the booming UK market. It is your chance to increase the number of customers worldwide, generate high profits and contribute to the local economy by creating jobs. Initially, the visa is issued for three years and can be extended any number of times, and everytimeyou can set up a new company
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6 offset-xl-1">
                        <div className="contact-form-box shadow-box mb--30">
                            <h3 className="title">Get a quote now</h3>
                            <FormOne />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutTwo;