import React from 'react';
import FormOne from '../contact/FormOne';
import Accordion from 'react-bootstrap/Accordion';
import { FaCompress, FaGlobe, FaAdjust, FaBusinessTime, FaPersonBooth, FaWindowMinimize, FaAngellist, FaEarlybirds, FaMoneyBillAlt, FaAddressCard, FaAccessibleIcon, FaAcquisitionsIncorporated, FaTemperatureHigh, FaStarOfDavid, FaAd, FaNewspaper, FaShoppingCart } from 'react-icons/fa';


const AboutTwo = () => {
  return (
    <div className="section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="why-choose-us">
              <div className="section-heading heading-left" style={{ textAlign: 'center' }}>
                {/* <span className="subtitle">About Us</span>
                                <h3 className="title">Why branding matters?</h3>*/}

                <h1 class="title h2">Chat With Us</h1>
                <p style={{ textAlign: 'center' }}>
                  Chat is available
                </p>
                <p style={{ textAlign: 'center' }}>
                  24 hours / 7-days a week
                </p>
                
                <a aria-label="Chat on WhatsApp" href="https://wa.me/+9647510180001">
                  <img src="/images/whats_app_button.png" />
                </a>
                <br />
                <br />
                <a aria-label="Chat on Telegram" href="https://t.me/AbtixCo_com">
                  <img src="/images/telegram_button.png" />
                </a>

              </div>
            </div>
          </div>
          <div className="col-xl-5 col-lg-6 offset-xl-1">
            <div className="contact-form-box shadow-box mb--30">
              <h3 className="title">Get a quote now</h3>
              <FormOne />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutTwo;

