import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import ServicePropOne from '../component/service/ServicePropOne';
import ServiceProp from '../component/service/ServiceProp';
import AboutThree from '../component/about/AboutThree';
import CounterUpTwo from '../component/counterup/CounterUpTwo';
import ServiceData from "../data/service/ServiceMain.json";
import AboutThreeCompanyRegistration from "../component/about/AboutThreeCompanyRegistration"

const ServiceTwoCustom = ({ cateFilter, pageTitle }) => {

    let catData = ServiceData.filter(item => item.cate == cateFilter);

    return (
        <>
            <SEO title={pageTitle} />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                {/* <BcrumbBannerOne 
                title="Best solutions for your business"
                paragraph ="Give your business a unique logo to stand out from crowd. We’ll create logo specifically for your company."
                styleClass=""
                mainThumb="/images/banner/banner-thumb-4.png"
            /> */}
                {/* <CounterUpTwo /> */}
                <div className="section section-padding bg-color-light">
                    <div className="container">
                        <SectionTitle
                            subtitle="What We Can Do For You"
                            title={pageTitle}
                            description=""
                            textAlignment="heading-left"
                            textColor=""
                        />
                        <div className="row">
                            <ServiceProp colSize="col-lg-4 col-md-6" serviceStyle="service-style-2" serviceData={catData} />
                        </div>
                    </div>
                </div>

                {
                    (cateFilter == "cat1") ?
                        <AboutThreeCompanyRegistration />
                        :
                        <AboutThree />
                }

                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default ServiceTwoCustom;