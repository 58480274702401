import React from 'react';
import FormQuoteIraqCo from '../contact/FormQuoteIraqCo';
import Accordion from 'react-bootstrap/Accordion';
import { FaCompress, FaCode, FaGlobe, FaAdjust, FaBusinessTime, FaPersonBooth, FaWindowMinimize, FaAngellist, FaEarlybirds, FaMoneyBillAlt, FaAddressCard, FaAccessibleIcon, FaAcquisitionsIncorporated, FaTemperatureHigh, FaStarOfDavid, FaAd, FaNewspaper, FaShoppingCart } from 'react-icons/fa';


const AboutTwo = () => {
    return (
        <div className="section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="why-choose-us">
                            <div className="section-heading heading-left">
                                {/* <span className="subtitle">About Us</span>
                                <h3 className="title">Why branding matters?</h3>*/}
                                <p>
                                    Iraq is a country with enormous economic potential, but doing business there involves its own set of unique challenges.
                                </p>
                                <p>
                                    The good news is that Iraq remains open for business, and there is goodwill toward investors.
                                </p>
                                <p>
                                    In addition to the vast proven oil reserves in the country, further massive revenues are sure to flow from Iraq’s gas industry, which is slowly but steadily developing.
                                </p>
                                <p>
                                    Untapped opportunities exist and will continue to do so for many years to come in almost every sector ranging from manufacturing, IT, telecommunications, and agriculture to retail, health care, education, construction, banking, and professional services.
                                </p>
                                <p>
                                    With ABTIX we ensure deep commitment towards working across organizations and offer business setup services in Iraq – South, We develop a speedy route to enter the Global market for several multinationals
                                </p>
                                <p>
                                    We will help you to register a local or domestic company, or if you want, we can help you to register your branch in Iraq. Our services are provided with high quality and reliable management.
                                </p>
                                <p>
                                    ABTIX LLC is here to support you with the name, Company formation, registration with Ministry of Oil and Lawyer registration along with finding an office for your business in Baghdad or Basra. We are a team of experts that provide you with the best services as per your requirements.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6 offset-xl-1">
                        <div className="contact-form-box shadow-box mb--30">
                            <h3 className="title">Get a quote now</h3>
                            <FormQuoteIraqCo />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutTwo;