import React from 'react';
import FormOne from '../contact/FormOne';
import Accordion from 'react-bootstrap/Accordion';
import { FaCompress, FaCode, FaGlobe, FaAdjust, FaBusinessTime, FaPersonBooth, FaWindowMinimize, FaAngellist, FaEarlybirds, FaMoneyBillAlt, FaAddressCard, FaAccessibleIcon, FaAcquisitionsIncorporated, FaTemperatureHigh, FaStarOfDavid, FaAd, FaNewspaper, FaShoppingCart } from 'react-icons/fa';


const AboutTwo = () => {
    return (
        <div className="section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="why-choose-us">
                            <div className="section-heading heading-left">
                                {/* <span className="subtitle">About Us</span>
                                <h3 className="title">Why branding matters?</h3>*/}
                                <p>
                                    USA is a one-stop destination, filled with brilliant options like fascinating places, numerous business opportunities, world-class medical facilities et al. These are the reasons that prompt people to visit the USA and take a glimpse of a lifestyle there and witness beautiful US destinations that are renowned world over. If you are also one of the aspirants of the US visit visa.
                                </p>
                                <p>
                                    You may argue that you are from a country that enjoys the privilege of gaining an entry into the USA without any Visa. Sure, there are countries that have visa-free travel permission for the United States but of course, we offer assistance for residents of countries who cannot escape the necessity to obtain B1 and B2 visa for the USA which is extended depending upon the purpose of visit.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6 offset-xl-1">
                        <div className="contact-form-box shadow-box mb--30">
                            <h3 className="title">Get a quote now</h3>
                            <FormOne />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutTwo;