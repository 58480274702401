import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';

const Datas = [
    {
        id: 1,
        title: "Submit the application form and upload your documents, You will receive a confirmation email from us in regards to your application.",       
        para: "",
    },
    {
        id: 2,
        title: "2nd step is you need to arrange the payment, this can be done online but we also accept Credit or debit card, bank transfer or cash at our offices.",
        para: "",
    },
    {
        id: 3,
        title: "Our registration process is simple and straightforward. Your process will be started with our Operation team. They will contact you with the registration process and then you can have all the documentation ready.",
        para: "",
    },
    {
        id: 4,
        title: "After the initial communication, we will send you all the necessary documents to complete your registration.",
        para: "",
    }


   
]




const AboutThreeCompanyRegistration = () => {
    return (
        <div className="section section-padding bg-color-dark pb--80 pb_lg--40 pb_md--20">
            <div className="container">
                <SectionTitle 
                    subtitle=""
                    title="Company Registration Procedure"
                    description=""
                    textAlignment="heading-left heading-light-left mb--100"
                    textColor=""
                />

                <div className="row">
                    {Datas.map((data) => (
                        <div className="col-lg-3" key={data.id}>
                            <div className="about-quality">
                                <h3 className="sl-number">{data.id}</h3>
                                <h5 className="title">{data.title}</h5>
                                <p>{data.para}</p>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
            <ul className="list-unstyled shape-group-10">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-1.png"} alt="Circle" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-3.png"} alt="Circle" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"} alt="Circle" /></li>
            </ul>
        </div>
    )
}

export default AboutThreeCompanyRegistration;