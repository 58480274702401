import React from 'react';
import { Link } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas'
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaBehance, FaPhone, FaFax, FaInstagram } from "react-icons/fa";


const OffcanvasMenu = ({ offcanvasShow, offcanvasHide }) => {
    return (
        <Offcanvas show={offcanvasShow} onHide={offcanvasHide} placement="end" className="header-offcanvasmenu">
            <Offcanvas.Header closeButton></Offcanvas.Header>
            <Offcanvas.Body>
                {/* <form action="#" className="side-nav-search-form">
                    <div className="form-group">
                        <input type="text" className="search-field" name="search-field" placeholder="Search..." />
                        <button className="side-nav-search-btn"><i className="fas fa-search"></i></button>
                    </div>
                </form> */}
                <div className="row ">
                    <div className="col-lg-5 col-xl-6">
                        <ul className="main-navigation list-unstyled">
                            <li><Link to={process.env.PUBLIC_URL + "/company-registration"}>Company Registration</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/visa-residency"}>Visa & Residency</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/legal-consulting"}>Legal Consulting</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/information-technology"}>IT Services</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/financial-service"}>Financial Services</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/3pl-service"}>3PL Service</Link></li>
                        </ul>
                    </div>
                    <div className="col-lg-7 col-xl-6">
                        <div className="contact-info-wrap">
                            <div className="contact-inner">
                                {/* <address className="address">
                                    <span className="title">Contact Information</span>
                                    <p>Theodore Lowe, Ap #867-859 <br /> Sit Rd, Azusa New York</p>
                                </address>  */}
                                <address className="address">
                                    <span className="title">We're Available 24/7. Call Now.</span>
                                    <a href="tel:+1 844 882 4123" className="tel"><FaPhone /> (+1) 844 882 4123</a>
                                    <a href="tel:+971 50 232 9559" className="tel"><FaPhone /> (+971) 50 232 9559</a>
                                    <a href="tel:+964 751 018 0001" className="tel"><FaPhone /> (+964) 751 018 0001</a>
                                </address>
                            </div>
                            <div className="contact-inner">
                                <h5 className="title">Find us here</h5>
                                <div className="contact-social-share">
                                    <ul className="social-share list-unstyled">
                                        <li>
                                            <a href="https://www.facebook.com/Abtixco"><FaFacebookF /></a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/abtixco/"><FaInstagram /></a>
                                        </li>
                                        <li>
                                            <a href="https://www.linkedin.com/company/abtix/"><FaLinkedinIn /></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default OffcanvasMenu;
