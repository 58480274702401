import axios from 'axios';
import React from 'react'
import { FaEnvelopeOpen } from "react-icons/fa";

const SubscriptionForm = () => {

    const [email, setEmail] = React.useState('');
    const [succeed, setSucceed] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [emailWarning, setEmailWarning] = React.useState(false);
    const [messages, setMessages] = React.useState([]);


    const emailHandler = (e) => {
        setEmail(e.target.value);
    }

    const clickHandler = () => {

        setError(false);
        setEmailWarning(false);
        setSucceed(false);

        if (!validateEmail(email)) {
            setEmailWarning(true);
            return;
        }

        let messageArr = [];
        setLoading(true);
        
        const api_url = process.env.REACT_APP_API_URL;
        const website_id = process.env.REACT_APP_WEBSITE_ID;

        axios.post(api_url + 'subscription/register', {
            email: email,
            websiteREf: website_id
        })
            .then(response => {
                debugger;
                setSucceed(true);
                setLoading(false);
            })
            .catch(error => {
                debugger;
                if (error.response && error.response.status == 400) {
                    error.response.data.message.map(p => {
                        messageArr.push(p);
                    })
                }
                else {
                    messageArr.push("Failed to connect to server")
                }
                setError(true);
                setLoading(false);
                setMessages(messageArr);
            });

    }

    //Check if email is valid
    const validateEmail = (email) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    return (
        <div className="footer-newsletter">
            <h2 className="title">Get in touch!</h2>
            {/* <p>Fusce varius, dolor tempor interdum tristique, dui urna bib endum magna, ut ullamcorper purus</p> */}
            {/* <form> */}
            {succeed &&
                <div className="alert alert-success" role="alert">
                    <strong>Success!</strong>
                </div>
            }
            {emailWarning &&
                <div className="alert alert-warning" role="alert">
                    <strong>Please enter a valid email address</strong>
                </div>
            }
            {error &&
                <div className="alert alert-danger" role="alert">
                    <strong>Error!</strong>
                    {messages.length > 0 &&
                        <ul>
                            {messages.map((message) => {
                                return (
                                    <li>{message}</li>
                                )
                            })}
                        </ul>
                    }
                </div>
            }
            <div className="input-group">
                <input type="email" className="form-control" placeholder="Email address" onChange={emailHandler} />
                <span className="mail-icon"><FaEnvelopeOpen /> </span>

                {loading &&
                    <button class="subscribe-btn" type="button" disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </button>
                }
                {!loading &&
                    <button className="subscribe-btn" type="submit" onClick={clickHandler}>Subscribe</button>
                }
            </div>
            {/* </form> */}
        </div>)
}
export default SubscriptionForm;