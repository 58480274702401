import React from 'react';
import { Link } from 'react-router-dom';
import { FaAngleDown } from "react-icons/fa";


const Nav = () => {
    return (
        <nav className="mainmenu-nav">
            <ul className="mainmenu">
                <li><Link to={process.env.PUBLIC_URL + "/about-us"}>About ABTIX</Link></li>
                <li className="menu-item-has-children">
                    <Link to="/services">Services<FaAngleDown /></Link>
                    <ul className="axil-submenu">
                        <li><Link to={process.env.PUBLIC_URL + "/company-registration"}>Company Registration</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/visa-residency"}>Visa & Residency Service</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/legal-consulting"}>Legal Consulting Service</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/information-technology"}>IT Services</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/financial-service"}>Financial Services</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/3pl-service"}>3PL Service</Link></li>
                    </ul>
                </li>
                {/* <li className="menu-item-has-children">
                    <Link to="#">Company Registration<FaAngleDown /> </Link>
                    <ul className="axil-submenu">
                        <li><Link to={process.env.PUBLIC_URL + "/service-details/"}>United States</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/service-details/"}>United Arab Emirates</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/#"}>United Kingdom</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/#"}>Republic of IRAQ</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/#"}>Kurdistan Region</Link></li>                        
                    </ul>
                </li> */}
                {/* <li className="menu-item-has-children">
                    <Link to="#">Visa and Residency Service<FaAngleDown /></Link>
                    <ul className="axil-submenu">
                        <li><Link to={process.env.PUBLIC_URL + "/#"}>United Arab Emirates</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/#"}>United Kingdom</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/#"}>Kurdistan Region of IRAQ</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/#"}>Visa Consulting for USA</Link></li>
                    </ul>
                </li> */}
                {/* <li className="menu-item-has-children">
                    <Link to="#">Legal Consulting Service<FaAngleDown /></Link>
                    <ul className="axil-submenu">
                        <li><Link to={process.env.PUBLIC_URL + "/#"}>Trade Mark Registration</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/#"}>Document Notarization</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/#"}>Apostille Service</Link></li>                                     
                    </ul>
                </li> */}
                {/* <li className="menu-item-has-children">
                    <Link to="#">IT Services<FaAngleDown /></Link>
                    <ul className="axil-submenu">
                        <li><Link to={process.env.PUBLIC_URL + "/#"}>Host and Domain</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/#"}>Website Design</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/#"}>Logo and Marketing Materials Developing</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/#"}>Social Media Marketing </Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/#"}>WhatsApp & Telegram Service </Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/#"}>Mobile Application </Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/#"}>Customize Software</Link></li>
                    </ul>
                </li> */}
                {/* <li className="menu-item-has-children">
                    <Link to="#">Financial Services<FaAngleDown /></Link>
                    <ul className="axil-submenu">
                        <li><Link to={process.env.PUBLIC_URL + "/#"}>Banking Service</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/#"}>Credit & Debit Cards</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/#"}>Investment Consulting</Link></li>
                    </ul>
                </li>            
                <li><Link to={process.env.PUBLIC_URL + "/#"}>3PL Service</Link></li> */}
                <li><Link to={process.env.PUBLIC_URL + "/blog"}>Blog</Link></li>
                <li><Link to={process.env.PUBLIC_URL + "/contact"}>Contact</Link></li>
            </ul>
        </nav>
    )
}

export default Nav;